import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Button,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskToolbar from "../../../common/TaskToolbar";
import { useTheme } from "@emotion/react";
import { UserContext } from "../../../context/MyContext";
import JsPlayground from "../ApiTesting/JsPlayground";


const ManageVariables = () => {
       const theme = useTheme();
       const {mVariables, setmVariables } = useContext(UserContext);
  const [rows, setRows] = useState([{ name: "", value: "", description: "" }]);

  // Load data from localStorage on component mount
  useEffect(() => {
    const loadVariables = async () => {
     
      if (mVariables?.length > 0) {
        setRows(mVariables);
      }
    };
    loadVariables();
  }, []);

  // Save data to localStorage whenever rows change
  useEffect(() => {
    localStorage.setItem("variables", JSON.stringify(rows));
    setmVariables(rows)
  }, [rows]);

  const handleAddRow = () => {
    setRows([...rows, { name: "", value: "", description: "" }]);
  };

  const handleDeleteRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const validateRows = () => {
    return rows.filter((row) => row.name.trim() !== ""); // Ignore rows with empty 'name'
  };

  return (
    <div style={{backgroundColor: theme.palette.background.default, minHeight: 700,}}>
          <TaskToolbar setState={false} backenabled={true}/>
    <div style={{ padding: "20px" }}>
      <h2>Manage Variables</h2>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddRow}
        style={{ marginBottom: "10px" }}
      >
        Add New
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    value={row.name}
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value)
                    }
                    placeholder="Enter name"
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={row.value}
                    onChange={(e) =>
                      handleInputChange(index, "value", e.target.value)
                    }
                    placeholder="Enter value"
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={row.description}
                    onChange={(e) =>
                      handleInputChange(index, "description", e.target.value)
                    }
                    placeholder="Enter description"
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    
    </div>
  );
};

export default ManageVariables;
