import { TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Fonts } from "../../../assets/fonts/Fonts";
import { useTheme } from "@emotion/react";
import { UserContext } from "../../../context/MyContext";

const mVariables = [
  { name: "name", value: "John" },
  { name: "age", value: "30" },
  { name: "city", value: "New York" },
];
const VariableHelper = ({
  label = "",
  value,
  onChange = () => {},
  fullWidth = true,
  variant = "outlined",
  required = false,
  style = {},
  disabled = false,
  placeholder = "",
}) => {
  const {mVariables} = useContext(UserContext);
  const [inputValue, setInputValue] = useState(value || "");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredVariables, setFilteredVariables] = useState([]);
  const theme = useTheme();
  const renderPreview = (text) => {
    return text.replace(/\$\{(\w+)\}/g, (match, key) => {
      const variable = mVariables.find((v) => v.name === key);
      return variable ? variable.value : match;
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    try {
      const renderedValue = renderPreview(value);
      onChange(renderedValue);
    } catch (e) {
      console.error("Error in handleChange callback:", e);
    }

    const match = value.match(/\$\{(\w*)$/);
    if (match) {
      setFilteredVariables(
        mVariables.filter((v) => v.name.startsWith(match[1]))
      );
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSelectVariable = (variableName) => {
    const updatedValue = inputValue.replace(/\$\{\w*$/, `\${${variableName}}`);
    setInputValue(updatedValue);
    setShowSuggestions(false);

    try {
      const renderedValue = renderPreview(updatedValue);
      onChange(renderedValue);
    } catch (e) {
      console.error("Error in handleSelectVariable callback:", e);
    }
  };

  return (
    <>
      <TextField
        label={label}
        value={inputValue}
        onChange={handleChange}
        fullWidth={fullWidth}
        variant={variant}
        required={required}
        style={{ fontFamily: Fonts.roboto_mono, ...style }}
        disabled={disabled}
        placeholder={placeholder}
      />

      {showSuggestions && (
        <ul
          style={{
            position: "absolute",
            top: "75%", // Position below the input field
            left: 0,
            right: 0,
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            border: "1px solid #ddd",
            borderRadius: "4px",
            zIndex: 1000,
            padding: "8px 0",
            margin: 0,
            listStyleType: "none",
          }}
        >
            <Typography
            gutterBottom
            style={{
              color: theme.palette.text.primary,
              fontFamily: Fonts.roboto_mono,
              fontWeight: "bold",
              marginBottom: 0,
              fontSize: 15,
              padding:15
            }}
          >
            Variables
          </Typography>
          
          {filteredVariables.map((v,i) => (
            <li
              key={i}
              onClick={() => handleSelectVariable(v.name)}
              style={{
                cursor: "pointer",
                padding: "8px 12px",
                backgroundColor: "#f9f9f9",
                margin: "2px 0",
                borderRadius: "4px",
                transition: "background-color 0.2s",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#eaeaea")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#f9f9f9")}
            >
              {v.name}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default VariableHelper;
