import React, { useState, useEffect, useContext } from "react";
import MonacoEditor from "@monaco-editor/react";
import { Button, Typography, Box } from "@mui/material";
import { UserContext } from "../../../context/MyContext";

const defaultScript = `
/* Use the mVariables object to manage variables. 
 you can access your response by res variable */


// Example operations:

 /* Add a variable */
//mVariables.addVariable("exampleKey", res.title);

/* Update a variable */
//mVariables.updateVariable("exampleKey", "newValue");

/* Delete a variable */
//mVariables.deleteVariable("exampleKey");
`;

const JsPlayground = ({showButton=false,res}) => {
  const {mVariables, setmVariables } = useContext(UserContext);
  const [script, setScript] = useState(defaultScript);
  const [error, setError] = useState("");

 

  const saveVariables =  (variables) => {
    try {
      // Remove duplicate items before saving
      const uniqueVariables = [...new Map(variables.map((v) => [v.name, v])).values()];
      
      setmVariables(uniqueVariables);


     localStorage.setItem("variables", JSON.stringify(uniqueVariables));
      
    } catch (e) {
      console.error("Error saving variables to AsyncStorage:", e);
    }
  };

  const runScript = () => {
    console.log('runScript');
    setError("");
    try {
      // Define the mVariables object with necessary methods
      const mVariablesProxy = {
        addVariable: (key, value) => {
          setmVariables((prev) => {
            const updated = [...prev, { name: key, value, description: "desc" }];
            saveVariables(updated);
            return updated;
          });
        },
        deleteVariable: (key) => {
          setmVariables((prev) => {
            const updated = prev.filter((v) => v.name !== key);
            saveVariables(updated);
            return updated;
          });
        },
        updateVariable: (key, newValue) => {
          setmVariables((prev) => {
            const updated = prev.map((v) =>
              v.name === key ? { ...v, value: newValue } : v
            );
            saveVariables(updated);
            return updated;
          });
        },
        getAll: () => {
          return mVariables;
        },
      };

      // Execute the script
      const func = new Function("mVariables", "res", script);
      func(mVariablesProxy, JSON.parse(res)); 
    } catch (e) {
      setError(e.message);
    }
  };


  useEffect(() => {
        console.log("change",res)
        if(res!=null && res!=undefined && res!=''){
         
         runScript();

        }
    
  }, [res])

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Post Request Script
      </Typography>
      <MonacoEditor
        height="200px"
        defaultLanguage="javascript"
        value={script}
        onChange={(newValue) => setScript(newValue)}
        options={{
          fontSize: 14,
          minimap: { enabled: false },
          automaticLayout: true,
        }}
      />
     {showButton && <Button
        variant="contained"
        color="primary"
        onClick={runScript}
        sx={{ mt: 2 }}
      >
        Run Script
      </Button>}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default JsPlayground;
